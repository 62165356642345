//
//
//
//
//
//
//
//
//
//
//

import ProgramSlide from '~/components/pages/Home/LatestPrograms/ProgramsCarousel/ProgramSlide'

export default {
  name: 'LatestProgramsCarousel',
  components: {
    ProgramSlide,
  },
  props: {
    segmentData: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
    model: null,
  }),
}
