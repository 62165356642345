//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SegmentMeta',
  props: {
    banner: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
  },
}
