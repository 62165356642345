//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ProgramSlide',
  props: {
    segment: {
      type: Object,
      default: null,
    },
  },
  computed: {
    toLink() {
      return `/programs/${this.segment.slug}`
    },
    imageSrc() {
      return `${process.env.API_ASSETS_IMAGE_PATH}/${this.segment.image}`
    },
    slideBg() {
      return `background-image: url('${process.env.API_ASSETS_IMAGE_PATH}/${this.segment.image}')`
    },
  },
}
