//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex'
import HeroSlider from '~/components/pages/Home/HeroSlider'
import ProgramSegment from '~/components/pages/Home/ProgramSegment'
import LatestPrograms from '~/components/pages/Home/LatestPrograms'
// import RadioSchedule from '~/components/RadioShcedule'
import WidgetFacebook from '~/components/Widgets/WidgetFacebook'

export default {
  components: {
    HeroSlider,
    ProgramSegment,
    // RadioSchedule,
    LatestPrograms,
    WidgetFacebook,
  },
  data: () => ({
    dataExclusiveInterviews: {
      segmentTitle: 'Chooty Malli Podi Malli',
      segmentInfo: {
        subTitle: 'Chooty Malli Podi Malli',
        description: 'hilarious duo on FM Derana!',
        banner: `${process.env.LOCAL_ASSETS_IMAGE_PATH}/banners/banner-cmpm.png`,
      },
    },
    dataApiNodannaRadio: {
      segmentTitle: 'FM Derana Boom Town',
      segmentInfo: {
        subTitle: 'FM Derana Boom Town',
        description: 'Sri Lankan music live on stage at the most spectacular show of the year!',
        banner: `${process.env.LOCAL_ASSETS_IMAGE_PATH}/banners/banner-boom-town.png`,
      },
    },
    dataChartShow: {
      segmentTitle: 'FM Derana Chart Show',
      segmentInfo: {
        subTitle: 'FM Derana Chart Show',
        description: 'Latest episodes from the FM Derana Chart Show',
        banner: `${process.env.LOCAL_ASSETS_IMAGE_PATH}/banners/banner-chart-show.png`,
      },
    },
    dataLatestPrograms: {
      segmentTitle: 'Latest Programs',
    },
  }),
  computed: {
    ...mapState([
      'featuredBanners',
      'exclusiveInterviews',
      'apiNodannaRadio',
      'latestPrograms',
      'chartShow',
    ]),
  },
  mounted() {
    this.getFeaturedBanners()
    this.getExclusiveInterviews()
    this.getApiNodannaRadio()
    this.getLatestPrograms()
    this.getChartShow()
  },
  methods: {
    ...mapActions([
      'getFeaturedBanners',
      'getExclusiveInterviews',
      'getApiNodannaRadio',
      'getLatestPrograms',
      'getChartShow',
    ]),
  },
}
