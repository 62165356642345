//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'HeroSlider',
  props: {
    banners: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
    model: 0,
    segmentDialog: false,
  }),
  computed: {
    isMobile() {
      if (this.$mq === 'xs') {
        return true
      } else {
        return false
      }
    },
  },
  watch: {
    model(newStep, oldStep) {
      if (newStep >= this.banners.length) {
        this.model = 0
      }
    },
  },
  created() {
    this.autoSlide()
  },
  methods: {
    getBannerURI(banner) {
      return `${process.env.API_ASSETS_IMAGE_PATH}/${banner}`
    },
    getEmbedURL(videoId) {
      return `https://www.youtube.com/embed/${videoId}`
    },
    clickPrev() {
      if (this.model > 0) {
        this.model--
      }
    },
    clickNext() {
      if (this.model < this.banners.length) {
        this.model++
      }
    },
    autoSlide() {
      this.model = setInterval(() => {
        this.model++
      }, 5000)
    },
  },
}
