//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'WidgetFacebook',
  computed: {
    widgetURI() {
      return `${process.env.FACEBOOK_WIDGET_URI}`
    },
  },
}
