//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SegmentSlide',
  props: {
    segment: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    segmentDialog: false,
  }),
  methods: {
    getYoutubeURL(videoId) {
      return `https://www.youtube.com/watch?v=${videoId}`
    },
    getEmbedURL(videoId) {
      return `https://www.youtube.com/embed/${videoId}`
    },
  },
}
