//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SegmentSlide from '~/components/pages/Home/ProgramSegment/SegmentCarousel/SegmentSlide'

export default {
  name: 'SegmentCarousel',
  components: {
    SegmentSlide,
  },
  props: {
    segmentData: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
    model: null,
  }),
}
