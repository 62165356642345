//
//
//
//
//
//
//
//
//
//
//
//

import LatestProgramsCarousel from '~/components/pages/Home/LatestPrograms/LatestProgramsCarousel'

export default {
  name: 'LatestPrograms',
  components: {
    LatestProgramsCarousel,
  },
  props: {
    segmentTitle: {
      type: String,
      default: null,
    },
    segmentData: {
      type: Array,
      default: null,
    },
  },
}
